import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { showSnackbar } from "src/redux/snackbar/actions";
import {
  AUTHORIZATION_HEADER,
  DEFAULT_ERROR_MESSAGE,
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  RTK_API_BASE_URL,
} from "utils/constants";

export const productFamilyApi = createApi({
  reducerPath: "productFamilyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: RTK_API_BASE_URL,
    /*prepareHeaders: (headers, { getState }) => {
      const { token } = getState().auth;

      if (token) {
        headers.set(AUTHORIZATION_HEADER, token);
      }
      return headers;
    },*/
  }),
  extractRehydrationInfo(action) {
    if (action.type === HYDRATE) {
      return action.payload.api;
    }
  },
  tagTypes: ["product-family", "product-family-details", "product-family-for-lifecycle"],
  endpoints: (builder) => ({
    getAllproductFamily: builder.query({
      query: (payload) => ({
        url: `product-family`,
        method: METHOD_GET,
        params: payload,
      }),
      providesTags: [{ type: "product-family" }],
    }),
    addContentType: builder.mutation({
      query: ({ payload }) => ({
        url: `product-family`,
        method: METHOD_POST,
        body: payload,
      }),
      async onQueryStarted({ payload, onSuccessFn }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            showSnackbar({
              message: `${payload.isPageType ? "Page" : "Content"} type added successfully!`,
              type: "success",
            })
          );
          onSuccessFn(data);
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [{ type: "product-family" }],
    }),
    searchproductFamily: builder.mutation({
      query: ({ payload }) => ({
        url: `product-family/search`,
        method: METHOD_POST,
        body: payload,
      }),
      async onQueryStarted(
        { onSuccessFn = () => { }, snackbar = true },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;

          /*if (snackbar) {
            dispatch(
              showSnackbar({
                message: "Content types filtered successfully!",
                type: "success",
              })
            );
          }*/

          onSuccessFn(data);
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [{ type: "product-family" }],
    }),
    getContentType: builder.query({
      query: ({ id }) => `product-family/${id}`,
      providesTags: [{ type: "product-family-details" }],
      async onQueryStarted(
        { onSuccessFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;

          onSuccessFn(data);
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
    }),
    updateContentType: builder.mutation({
      query: ({ payload }) => ({
        url: `product-family/${payload.id}`,
        method: METHOD_PUT,
        body: payload,
      }),
      async onQueryStarted(
        { payload, onSuccessFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            showSnackbar({
              message: `${payload.name} updated successfully!`,
              type: "success",
            })
          );
          onSuccessFn();
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: "Something went wrong!",
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [
        { type: "product-family-details" },
        { type: "product-family" },
      ],
    }),
    deleteContentType: builder.mutation({
      query: ({ id }) => ({
        url: `product-family/${id}`,
        method: METHOD_DELETE,
      }),
      async onQueryStarted({ onSuccessFn = () => { }, onErrorFn = () => { } }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            showSnackbar({
              message: `Deleted successfully!`,
              type: "success",
            })
          );
          onSuccessFn(data);
        } catch ({ error }) {
          onErrorFn(error);
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [{ type: "product-family" }],
    }),
    addContentTypeField: builder.mutation({
      query: ({ id, payload }) => ({
        url: `product-family/${id}/fields`,
        method: METHOD_POST,
        body: payload,
      }),
      async onQueryStarted({ onSuccessFn }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            showSnackbar({
              message: `Field added succesfully`,
              type: "success",
            })
          );
          onSuccessFn();
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [{ type: "product-family-details" }],
    }),
    updateContentTypeField: builder.mutation({
      query: ({ id, payload }) => ({
        url: `product-family/${id}/fields`,
        method: METHOD_PUT,
        body: payload,
      }),
      async onQueryStarted(
        { onSuccessFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            showSnackbar({
              message: `Field updated succesfully`,
              type: "success",
            })
          );
          onSuccessFn();
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [{ type: "product-family-details" }],
    }),
    deleteContentTypeField: builder.mutation({
      query: ({ id, payload }) => ({
        url: `product-family/${id}/fields`,
        method: METHOD_DELETE,
        body: { id: payload.id },
      }),
      async onQueryStarted(
        { onSuccessFn = () => { }, onErrorFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            showSnackbar({
              message: `Field deleted succesfully`,
              type: "success",
            })
          );
          onSuccessFn();
        } catch ({ error }) {
          //  field cannot be removed to existing entries which are using this content type/fields
          onErrorFn(error.status);
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [{ type: "product-family-details" }],
    }),
    getContentTypeCollection: builder.query({
      query: ({ payload }) => ({
        url: `product-family/collection/type/${payload}`,
        method: METHOD_GET,
      }),
      async onQueryStarted(
        { onSuccessFn = () => { }, onErrorFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;

          onSuccessFn(data);
        } catch ({ error }) {
          onErrorFn(error.status);
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
    }),
    getContentTypeCollectionDetails: builder.query({
      query: ({ payload }) => {
        let url = `product-family/collection/${payload}`;
        if (payload?.id) {
          url = `product-family/collection/${payload.id}`;
          return {
            url: url,
            method: METHOD_GET,
            params: { type: payload.type },
          };
        }
        return {
          url: url,
          method: METHOD_GET,
        };
      },
      async onQueryStarted(
        { onSuccessFn = () => { }, onErrorFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;

          onSuccessFn(data);
        } catch ({ error }) {
          onErrorFn(error?.status);
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
    }),

    upload: builder.mutation({
      query: ({ payload }) => {
        return {
          url: `product-family/upload`,
          method: METHOD_POST,
          body: payload,
        }
      },
      async onQueryStarted(callbacks, args) {
        const { onSuccessFn = () => { }, onErrorFn = () => { } } = callbacks;
        const { dispatch, queryFulfilled } = args;

        try {

          const { data } = await queryFulfilled;

          dispatch(
            showSnackbar({
              message: "Type imported successfully!",
              type: "success",
            })
          );
          onSuccessFn(data);

        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
          onErrorFn();
        }
      },

      invalidatesTags: [{ type: "product-family-details" }],
    }),

    download: builder.mutation({
      query: (payload) => {
        return {
          url: `product-family/download`,
          method: METHOD_GET,
          params: { id: payload?.id },
        }
      },
      async onQueryStarted(callbacks, args) {
        const { onSuccessFn = () => { }, onErrorFn = () => { } } = callbacks;
        const { dispatch, queryFulfilled } = args;

        try {

          const { data } = await queryFulfilled;

          dispatch(
            showSnackbar({
              message: "Type exported successfully!",
              type: "success",
            })
          );
          onSuccessFn(data);

        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
          onErrorFn();
        }
      },

      invalidatesTags: [{ type: "product-family-details" }],
    }),

    addProductFamily: builder.mutation({
      query: ({ payload }) => ({
        url: 'product-family',
        method: METHOD_POST,
        body: payload,
      }),
      async onQueryStarted(
        { payload, onSuccessFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            showSnackbar({
              message: data?.message || `${payload?.name} added successfully!`,
              type: "success",
            })
          );
          onSuccessFn(data);
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: "Something went wrong!",
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [ "product-family" ],
    }),
    
    updateProductFamily: builder.mutation({
      query: ({ payload }) => ({
        url: 'product-family',
        method: METHOD_PUT,
        body: payload,
      }),
      async onQueryStarted(
        { payload, onSuccessFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            showSnackbar({
              message: data?.message || `${payload?.name} added successfully!`,
              type: "success",
            })
          );
          onSuccessFn(data);
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: "Something went wrong!",
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [ "product-family" ],
    }),
    
    productFamilyForLifecycle: builder.query({
      query: (payload) => ({
        url: `product-family/for-lifecycle`,
        method: METHOD_GET,
        params: payload,
      }),
      invalidatesTags: [{ type: 'product-family-for-lifecycle' }],
    }),
  }),
});

export const {
  useGetContentTypeQuery,
  useGetAllproductFamilyQuery,
  useAddContentTypeMutation,
  useUpdateContentTypeMutation,
  useDeleteContentTypeMutation,
  useAddContentTypeFieldMutation,
  useUpdateContentTypeFieldMutation,
  useDeleteContentTypeFieldMutation,
  useGetContentTypeCollectionQuery,
  useSearchproductFamilyMutation,
  useUploadMutation,
  useDownloadMutation,
  useAddProductFamilyMutation,
  useUpdateProductFamilyMutation,
  useProductFamilyForLifecycleQuery,
} = productFamilyApi;

// Selectors
export const selectTypes =
  productFamilyApi.endpoints.getAllproductFamily.select();
