import { useMemo } from "react"
import { useContext, createContext, useState } from "react"

const UIContext = createContext({
  name: 'uicontext'
})

export const ManagedUIContext = ({ children }) => {
  const [isEditProductOpen, setIsEditProductOpen] = useState(false)
  const [toastValues, setToastValues] = useState({
    title: undefined,
    message: undefined,
    error: undefined,
  })

  const resetToastValues = () => {
    setToastValues({
      title: undefined,
      message: undefined,
      error: undefined,
    })
  }

  const contextValues = useMemo(
    () => ({
      isEditProductOpen,
      setIsEditProductOpen,
      toastValues,
      setToastValues,
      resetToastValues,
    }),
    [isEditProductOpen, setIsEditProductOpen, toastValues, setToastValues, resetToastValues]
  );

  return (
    <UIContext.Provider value={contextValues}>
      {children}
    </UIContext.Provider>
  )
}

export const useUI = () => {
  const context = useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}
