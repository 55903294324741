import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import {
    AUTHORIZATION_HEADER,
    METHOD_POST,
    RTK_API_BASE_URL,
} from "@utils/constants";

export const rolesApi = createApi({
    reducerPath: "rolesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: RTK_API_BASE_URL,
    }),
    extractRehydrationInfo(action) {
        if (action.type === HYDRATE) {
            return action.payload.api;
        }
    },
    tagTypes: ["Roles"],
    endpoints: (builder) => ({
        getAllRoles: builder.query({
            query: ({ orgId }) => ({
                url: `role`,
                params: { orgId }
            }),
            providesTags: [{ type: "Roles" }],
        }),

        getAllRoleGroups: builder.mutation({
            query: ({payload}) => ({
                url: `role/groups`,
                method: METHOD_POST,
                body: payload
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    onSuccessFn(data);
                } catch ({ error }) {
                    onErrorFn(error);
                }
            },
            providesTags: [{ type: "Roles-Groups" }],
        }),
    }),
});

export const {
    useGetAllRolesQuery, useGetAllRoleGroupsMutation,
} = rolesApi;