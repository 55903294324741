import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { useUI } from '@context/ui';
import {
  ALERT_BOX_HIDE_DELAY,
  ALERT_BOX_ERROR_VARIANT,
  ALERT_BOX_INFO_VARIANT,
} from '@utils/constants';

export default function AlertBox() {
  const { toastValues, resetToastValues } = useUI();
  const shouldDisplay = Boolean(toastValues.message || toastValues.error);

  return (
    <ToastContainer
      className={`p-3 fixed right-0 min-w-[300px] ${
        shouldDisplay ? '!z-[99999]' : '!z-[1]'
      }`}
      position="top-end"
    >
      <Toast
        onClose={() => {
          resetToastValues();
        }}
        show={shouldDisplay}
        delay={ALERT_BOX_HIDE_DELAY}
        autohide
        bg={
          toastValues.error ? ALERT_BOX_ERROR_VARIANT : ALERT_BOX_INFO_VARIANT
        }
        className='font-medium text-white'
      >
        {/* <Toast.Header>
          <strong className="me-auto">{toastValues.title || ''}</strong>
        </Toast.Header> */}
        <Toast.Body>
          {toastValues.message || toastValues.error || ''}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
