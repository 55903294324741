/*! betterCommerceStorefront | Ⓒ 2022, Axtrum Solutions.
//@ Class: ParseUtil
//@ Inherits: <None>
//@ Implements: <None>
//@ Description: Utility class for parsing data.
*/
// import moment from 'moment';

import { DATE_FORMAT, TIME_FORMAT } from './constants';

/**
 * Parses boolean from string.
 * @param stringValue 
 * @returns 
 */
export const stringToBoolean = (stringValue) => {
    if (stringValue) {
        switch (stringValue.toLowerCase()) {
            case "true":
            case "1":
            case "on":
            case "yes":
                return true;
            default:
                return false;
        }
    }
    return false;
};


export const matchStrings = (input1, input2, ignoreCase = false) => {
    if (input1 && input2) {
        if (ignoreCase) {
            return (input1.toLowerCase() === input2.toLowerCase());
        }
        return (input1 === input2);
    }
    return false;
};

export const tryParseJson = (json) => {
    if (json) {
        let parsed = {};
        try {
            parsed = JSON.parse(json);
            return parsed;
        } catch (e) {
        }
    }
    return null;
};

export const utcToLocalDate = (date_to_convert) => {
    // date_to_convert.setMinutes(date_to_convert.getMinutes() + new Date().getTimezoneOffset());
    date_to_convert.setHours(date_to_convert.getHours() + 5);
    date_to_convert.setMinutes(date_to_convert.getMinutes() + 30);
    return date_to_convert;
};

/**
 * @param {string} value - Valid date value
 * @param {string} formatValue - Date format value
 * @returns {string} Formatted date
 */
// export const formatDate = (value = null, format = '') => {
//     const momentDate = moment(new Date(value));
//     if (momentDate.isValid()) {
//         return moment(new Date(value)).format(format);
//     }
//     return false;
// };
