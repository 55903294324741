import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { showSnackbar } from "src/redux/snackbar/actions";
import {
    AUTHORIZATION_HEADER,
    DEFAULT_ERROR_MESSAGE,
    METHOD_DELETE,
    METHOD_POST,
    METHOD_PUT,
    METHOD_PATCH,
    RTK_API_BASE_URL,
    METHOD_GET,
} from "utils/constants";

export const lifecycleApi = createApi({
    reducerPath: "lifecycleApi",
    baseQuery: fetchBaseQuery({
        baseUrl: RTK_API_BASE_URL,
        /*prepareHeaders: (headers, { getState }) => {
            const { token } = getState().auth;

            if (token) {
                headers.set(AUTHORIZATION_HEADER, token);
            }
            return headers;
        },*/
    }),
    extractRehydrationInfo(action) {
        if (action.type === HYDRATE) {
            return action.payload.api;
        }
    },
    tagTypes: ["Lifecycle", "Lifecycle-Users", "Lifecycle-Details", "Lifecycle-Task-Details", "Update-Lifecycle-Task-Details", "Lifecycle-User-Notifications", "Update-Lifecycle-User-Notifications", "Lifecycle-Task-List", "Lifecycle-Task-Dashboard", "Lifecycle-Task-Tracker"],
    endpoints: (builder) => ({

        getLifecycleTypes: builder.query({
            query: (payload) => ({
                url: `settings/lifecycle/status-list?id=${payload.id}`,
            }),
            providesTags: [{ type: "Lifecycle" }],
        }),

        getLifecycleUsers: builder.query({
            query: (payload) => ({
                url: `settings/lifecycle/users?roleGroupId=${payload.roleGroupId}`,
            }),
            providesTags: [{ type: "Lifecycle-Users" }],
        }),

        getLifecycleNotifications: builder.query({
            query: (payload) => ({
                url: `settings/lifecycle/notifications?userId=${payload.userId}`,
            }),
            providesTags: [{ type: "Lifecycle-User-Notifications" }],
        }),

        getAllLifecycles: builder.query({
            query: (payload) => ({
                url: `settings/lifecycle`,
                method: METHOD_GET,
                params: payload,
            }),
            providesTags: [{ type: "Lifecycle" }],
        }),

        getLifecycleDetails: builder.query({
            query: (payload) => ({
                url: `settings/lifecycle/${payload.id}`,
                method: METHOD_GET,
            }),
            providesTags: [{ type: "Lifecycle-Details" }],
        }),
        
        getLifecycleTaskList: builder.query({
            query: (params) => ({
                url: `mytasks`,
                method: METHOD_GET,
                params,
            }),
            providesTags: [{ type: "Lifecycle-Task-List" }],
        }),

        getLifecycleTaskDashboard: builder.query({
            query: (params) => ({
                url: `mytasks/dashboard`,
                method: METHOD_GET,
                params,
            }),
            providesTags: [{ type: "Lifecycle-Task-Dashboard" }],
        }),

        getLifecycleTaskDetails: builder.query({
            query: (payload) => ({
                url: `mytasks/${payload.id}`,
                method: METHOD_GET,
            }),
            providesTags: [{ type: "Lifecycle-Task-Details" }],
        }),

        createLifecycle: builder.mutation({
            query: ({ payload }) => ({
                url: `settings/lifecycle`,
                method: METHOD_POST,
                body: payload,
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = () => { }, snackbar = true },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    if (snackbar) {
                        dispatch(
                            showSnackbar({
                                message: `Lifecycle created successfully!`,
                                type: "success",
                            })
                        );
                    }

                    onSuccessFn(data);
                } catch ({ error }) {
                    onErrorFn(error?.data?.message
                        ? error.data.message
                        : DEFAULT_ERROR_MESSAGE);
                    dispatch(
                        showSnackbar({
                            message: error?.data?.message
                                ? error.data.message
                                : DEFAULT_ERROR_MESSAGE,
                            type: "fail",
                        })
                    );
                }
            },
            invalidatesTags: [{ type: "Lifecycle" }],
        }),

        createLifecycleStep: builder.mutation({
            query: ({ payload }) => ({
                url: `settings/lifecycle/${payload?.lifecycleId}/step`,
                method: METHOD_POST,
                body: payload,
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, snackbar = true, onErrorFn = () => { } },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    if (snackbar) {
                        dispatch(
                            showSnackbar({
                                message: `Lifecycle step created successfully!`,
                                type: "success",
                            })
                        );
                    }

                    onSuccessFn(data);
                } catch ({ error }) {
                    dispatch(
                        showSnackbar({
                            message: error?.data?.message
                                ? error.data.message
                                : DEFAULT_ERROR_MESSAGE,
                            type: "fail",
                        })
                    );
                    onErrorFn(error);
                }
            },
            invalidatesTags: [{ type: "Lifecycle" }],
        }),

        editLifecycleStep: builder.mutation({
            query: ({ payload }) => ({
                url: `settings/lifecycle/${payload?.lifecycleId}/step`,
                method: METHOD_PUT,
                body: payload,
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, snackbar = true, onErrorFn = () => { } },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    if (snackbar) {
                        dispatch(
                            showSnackbar({
                                message: `Lifecycle step updated successfully!`,
                                type: "success",
                            })
                        );
                    }

                    onSuccessFn(data);
                } catch ({ error }) {
                    dispatch(
                        showSnackbar({
                            message: error?.data?.message
                                ? error.data.message
                                : DEFAULT_ERROR_MESSAGE,
                            type: "fail",
                        })
                    );
                    onErrorFn(error);
                }
            },
            invalidatesTags: [{ type: "Lifecycle" }],
        }),

        saveLifecycle: builder.mutation({
            query: ({ payload }) => ({
                url: `settings/lifecycle/${payload?.id}`,
                method: METHOD_PUT,
                body: payload,
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, snackbar = true, onErrorFn = () => { } },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    if (snackbar) {
                        dispatch(
                            showSnackbar({
                                message: `Lifecycle saved successfully!`,
                                type: "success",
                            })
                        );
                    }

                    onSuccessFn(data);
                } catch ({ error }) {
                    dispatch(
                        showSnackbar({
                            message: error?.data?.message
                            ? error.data.message
                            : DEFAULT_ERROR_MESSAGE,
                            type: "fail",
                        })
                    );
                    onErrorFn(error?.data?.message || DEFAULT_ERROR_MESSAGE);
                }
            },
            invalidatesTags: [{ type: "Lifecycle" }],
        }),

        deleteLifecycle: builder.mutation({
            query: ({ payload }) => ({
                url: `settings/lifecycle/${payload?.id}/step/${payload.lifecycleStepId}`,
                method: METHOD_DELETE,
            }),
            invalidatesTags: [{ type: "Lifecycle" }],
        }),

        updateTaskDetails: builder.mutation({
            query: (payload) => ({
                url: `mytasks/${payload.id}`,
                method: METHOD_PUT,
                body: payload,
            }),
            providesTags: [{ type: "Update-Lifecycle-Task-Details" }],
        }),

        updateLifecycleNotification: builder.mutation({
            query: (payload) => ({
                url: `settings/lifecycle/notifications?notificationId=${payload.notificationId}`,
                method: METHOD_PUT,
                body: payload,
            }),
            providesTags: [{ type: "Update-Lifecycle-User-Notifications" }],
            invalidatesTags: [{ type: "Lifecycle-User-Notifications" }],
        }),

        getLifecycleTracker: builder.query({
            query: (params) => ({
                url: `get-tracker`,
                method: METHOD_GET,
                params,
            }),
            providesTags: [{ type: "Lifecycle-Task-Tracker" }],
        }),
        
    }),
});

export const {
    useGetAllLifecyclesQuery, useGetLifecycleDetailsQuery,useGetLifecycleTaskDetailsQuery, useGetLifecycleTypesQuery, useCreateLifecycleMutation, useSaveLifecycleMutation, useCreateLifecycleStepMutation, useDeleteLifecycleMutation, useGetLifecycleUsersQuery, useEditLifecycleStepMutation, useGetLifecycleNotificationsQuery,
} = lifecycleApi;