import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { showSnackbar } from "src/redux/snackbar/actions";
import {
  AUTHORIZATION_HEADER,
  DEFAULT_ERROR_MESSAGE,
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  RTK_API_BASE_URL,
} from "utils/constants";

export const ruleEngineApi = createApi({
  reducerPath: "ruleEngineApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${RTK_API_BASE_URL}/settings`,
  }),
  extractRehydrationInfo(action) {
    if (action.type === HYDRATE) {
      return action.payload.api;
    }
  },
  tagTypes: ["rule-engine", "rule-engine-details"],
  endpoints: (builder) => ({
    getAllRules: builder.query({
      query: (payload) => ({
        url: `rule-engine`,
        method: METHOD_GET,
        params: payload,
      }),
      providesTags: [{ type: "rule-engine" }],
    }),
    getRule: builder.query({
      query: ({ id }) => `rule-engine/${id}`,
      providesTags: [{ type: "rule-engine-details" }],
      async onQueryStarted(
        { onSuccessFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;

          onSuccessFn(data);
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
    }),
    addContentType: builder.mutation({
      query: ({ payload }) => ({
        url: `rule-engine`,
        method: METHOD_POST,
        body: payload,
      }),
      async onQueryStarted({ payload, onSuccessFn }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            showSnackbar({
              message: `${payload.isPageType ? "Page" : "Content"} type added successfully!`,
              type: "success",
            })
          );
          onSuccessFn(data);
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [{ type: "rule-engine" }],
    }),
    updateContentType: builder.mutation({
      query: ({ payload }) => ({
        url: `rule-engine/${payload.id}`,
        method: METHOD_PUT,
        body: payload,
      }),
      async onQueryStarted(
        { payload, onSuccessFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            showSnackbar({
              message: `${payload.name} updated successfully!`,
              type: "success",
            })
          );
          onSuccessFn();
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: "Something went wrong!",
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [
        { type: "rule-engine-details" },
        { type: "rule-engine" },
      ],
    }),
    addContentTypeField: builder.mutation({
      query: ({ id, payload }) => ({
        url: `rule-engine/${id}/fields`,
        method: METHOD_POST,
        body: payload,
      }),
      async onQueryStarted({ onSuccessFn }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            showSnackbar({
              message: `Field added succesfully`,
              type: "success",
            })
          );
          onSuccessFn();
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [{ type: "rule-engine-details" }],
    }),
    updateContentTypeField: builder.mutation({
      query: ({ id, payload }) => ({
        url: `rule-engine/${id}/fields`,
        method: METHOD_PUT,
        body: payload,
      }),
      async onQueryStarted(
        { onSuccessFn = () => { } },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            showSnackbar({
              message: `Field updated succesfully`,
              type: "success",
            })
          );
          onSuccessFn();
        } catch ({ error }) {
          dispatch(
            showSnackbar({
              message: error?.data?.message
                ? error.data.message
                : DEFAULT_ERROR_MESSAGE,
              type: "fail",
            })
          );
        }
      },
      invalidatesTags: [{ type: "rule-engine-details" }],
    }),
  }),
});

export const {
  useGetAllRulesQuery,
  useGetRuleQuery,
  useAddContentTypeMutation,
  useUpdateContentTypeMutation,
  useAddContentTypeFieldMutation,
  useUpdateContentTypeFieldMutation,
} = ruleEngineApi;

// Selectors
export const selectTypes =
  ruleEngineApi.endpoints.getAllRules.select();
