import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

///
import { AUTH_BASE_URL, ProductStagingStatus, SORT_ORDER } from "./constants";
import { PRODUCT_STAGING } from '@lib/textVariables';

export const getLoginUrl = (orgId = "", domainId = "") => {
    let url = `${AUTH_BASE_URL}?appUrl=${encodeURI(window.location.origin)}&source=external`;
    if (orgId) {
        url = `${url}&orgId=${orgId}`;

        if (domainId) {
            url = `${url}&domainId=${domainId}`;
        }
    }
    return url;
}

export const getLogoutUrl = () => {
    return `${AUTH_BASE_URL}/Account/Logout?appUrl=${window.location.origin}&source=external`;
}




export const getGridSettings = (currentPage, pageSize) => {
    const settings = {
        pageNumber: currentPage,
        pageSize: pageSize,
        totalRecords: 0,
        sortBy: "LastUpdated",
        sortOrder: SORT_ORDER.DESCENDING
    };
    return settings;
};

export const productStagingExportToExcel = (data, columns = []) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    // Define column headers
    worksheet.columns = columns;

    // Add data to the worksheet
    data.forEach((entry) => {
        entry.items.forEach((item) => {
            worksheet.addRow({
                stockCode: entry?.stockCode,
                batchName: item?.batchName,
                fieldLabel: item?.fieldLabel,
                fieldValue: item?.fieldValue || item?.fieldValueText,
                reviewer: item?.reviewer,
                status: item?.status,
                createdBy: item?.createdBy,
                created: item?.created,
            });
        });
    });

    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer]), document.title + '.xlsx');
    });
};

export const getStatusLabelText = (status = ProductStagingStatus.ALL) => {
    switch(status) {
        case ProductStagingStatus.SUBMITTED:
            return PRODUCT_STAGING.SUBMITTED
        case ProductStagingStatus.APPROVED:
            return PRODUCT_STAGING.APPROVED
        case ProductStagingStatus.REJECTED:
            return PRODUCT_STAGING.REJECTED
        default:
            return ''
    }
}