// Base Imports
import React, { Fragment, useCallback, useMemo, useState } from 'react';

// Package Imports
import classNames from 'classnames';
import { Avatar, Grid } from '@nextui-org/react';
import {
  UserIcon,
  UsersIcon,
  ShieldCheckIcon,
  InformationCircleIcon,
  FolderIcon,
  ChatIcon,
  InboxIcon,
  LogoutIcon,
  GlobeAltIcon,
  ArrowLeftIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';

import { getLoginUrl, getLogoutUrl } from '@utils/app-util';
import { resetUser } from '@redux/auth';
import { useTypedDispatch } from '@redux/store';

const NavMenuItemIcon = ({ iconClass, className }) => {
  switch (iconClass) {
    case 'fe-user':
      return <UserIcon className={className} />;
    case 'fe-users':
      return <UsersIcon className={className} />;
    case 'fe-shield':
      return <ShieldCheckIcon className={className} />;
    case 'fe-info':
      return <InformationCircleIcon className={className} />;
    case 'fe-folder':
      return <FolderIcon className={className} />;
    case 'fe-message-circle':
      return <ChatIcon className={className} />;
    case 'fe-inbox':
      return <InboxIcon className={className} />;
    case 'mdi mdi-web':
      return <GlobeAltIcon className={className} />;
    case 'fe-log-out':
        return <LogoutIcon className={className} />;
    default:
      return <></>;
  }
};

const NavMenuSwitchOrg = ({ orgs = [], orgId, displayText, iconClass, onToggleOpenOrgSwitch, }) => {
  const currentOrgName = useMemo(
    () => orgs?.find((x) => x.recordId === orgId)?.name,
    [orgs]
  );

  const showOrgClick = () => {
    onToggleOpenOrgSwitch();
  };

  if (orgs?.length < 1) {
    return <></>;
  }

  return (
    <Menu.Item>
      <a className="flex justify-between px-4 py-3 my-2 text-sm text-gray-700 border-t border-b">
        {orgId && (
          <>
            <div className="font-bold text-dark">
              <NavMenuItemIcon
                iconClass={iconClass}
                className="inline w-4 h-4 mr-2"
              />
              {currentOrgName}
            </div>
            <div
              className="text-blue-600 font-semibold relative top-0.5 text-xs cursor-pointer"
              onClick={showOrgClick}
            >
              {displayText}
            </div>
          </>
        )}
      </a>
    </Menu.Item>
  );
};

const AccountNavMenu = ({ name, orgId, navTree }) => {
  const navMenu = navTree?.navTree?.profileItems;
  const router = useRouter();
  const dispatch = useTypedDispatch();
  const [openOrgSwitch, setOpenOrgSwitch] = useState(false);

  const onToggleOpenOrgSwitch = () => setOpenOrgSwitch(!openOrgSwitch);

  const switchOrg = useCallback((e, orgId) => {
    e.preventDefault()
    if (orgId) {
      const url = getLoginUrl(orgId)
      router.replace(url)
    }
  }, [])

  return (
    <>
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="flex items-center bg-white rounded-full cursor-pointer nav-avatar">
            <span className="sr-only">Open user menu</span>
            <Grid>
              <Avatar
                size="md"
                text={name || 'Hi'}
                color="secondary"
                textColor="white"
                bordered
                className="cursor-pointer"
              />
            </Grid>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div>
            <Menu.Items className="absolute right-0 z-50 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg w-60 ring-1 ring-black ring-opacity-5 focus:outline-none min-250">
              {navMenu?.map((navMenu) => (
                <div key={navMenu?.id} className={navMenu?.cssClass}>
                  <div className="flex flex-1 px-4 py-2" role="none"><p className="text-xs font-normal text-gray-600" role="none">{navMenu?.displayText}</p></div>
                  {navMenu?.items?.map((navMenuItem) => {
                    if (navMenuItem?.menu === 'Switch Org') {
                      return (
                        <NavMenuSwitchOrg
                          key={navMenuItem?.id}
                          {...navMenuItem}
                          orgs={navTree?.orgs}
                          orgId={orgId}
                          onToggleOpenOrgSwitch={onToggleOpenOrgSwitch}
                        />
                      );
                    }
                    return (
                      <Menu.Item key={navMenuItem?.id}>
                        {({ active }) => (
                          <a
                            href={navMenuItem?.link}
                            className={`block px-4 py-2 text-sm text-gray-500 ${
                              active ? 'bg-gray-100' : ''
                            } ${navMenuItem?.cssClass} `}
                            onClick={(e) => {
                              if (navMenuItem?.menu === "Logout") {
                                e.preventDefault()
                                const redirectUrl = getLogoutUrl()
                                window.location.href = redirectUrl
                                setTimeout(() => {
                                    dispatch(resetUser())
                                }, 200)
                              }
                            }}
                          >
                            <NavMenuItemIcon
                              iconClass={navMenuItem?.iconClass}
                              className="inline w-4 h-4 mr-2"
                            />
                            {navMenuItem?.displayText}
                          </a>
                        )}
                      </Menu.Item>
                    )
                  })}
                </div>
              ))}
            </Menu.Items>
          </div>
        </Transition>
      </Menu>

      {/* ORG SWITCH TRANSITION PANEL USING HEADLESS UI COMPONENTS */}
      <Transition.Root show={openOrgSwitch} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={onToggleOpenOrgSwitch}
        >
          <div className="fixed inset-0 left-0 bg-black/30" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-400"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-400"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div className="relative z-50 flex flex-col h-full bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start">
                          <button
                            type="button"
                            className="mr-2 text-gray-900 bg-white rounded-md outline-none hover:text-gray-500"
                            onClick={onToggleOpenOrgSwitch}
                          >
                            <span className="sr-only">Close panel</span>
                            <ArrowLeftIcon
                              className="relative w-5 h-5 top-1"
                              aria-hidden="true"
                            />
                          </button>
                          <h2
                            id="slide-over-heading"
                            className="text-lg font-medium text-gray-900 uppercase"
                          >
                            Switch Orgs
                          </h2>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="p-6 overflow-y-auto inbox-widget">
                        {navTree?.orgs?.map((org, idx) => (
                          <a
                            href={`/Account/SwitchOrg/${org?.recordId}`}
                            onClick={(e) => switchOrg(e, org?.recordId)}
                            className={classNames(
                              org?.recordId == orgId
                                ? 'bg-blue-700 hover:bg-blue-700 text-white'
                                : 'bg-white text-gray-500 hover:bg-gray-100 dropdown-item',
                              'p-2 grid grid-cols-12 gap-x-1 pl-8 inbox-item mb-0 rounded-lg'
                            )}
                            key={org?.recordId}
                          >
                            <div className={`col-span-12 `}>
                              <h2 className={`font-normal text-sm`}>
                                {org?.recordId === orgId ? (
                                  <>
                                    <LockClosedIcon className="inline-block w-5 h-5 mr-1" />
                                    {org?.name} (Current)
                                  </>
                                ) : (
                                  <>
                                    <GlobeAltIcon className="inline-block w-5 h-5 mr-1" />
                                    {org?.name}
                                  </>
                                )}
                              </h2>
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AccountNavMenu;