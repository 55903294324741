import { createSlice } from "@reduxjs/toolkit"

// @helpers
import getGridColumnFields from "@helpers/get-grid-column-fields"
import getGridAutosave from '@helpers/get-grid-autosave'
import getGridCountryCodes from '@helpers/get-grid-country-codes'
import getGridShowOnlyMaster from '@helpers/get-grid-show-only-master'

// @utils
import { COUNTRY_CODE, GENDER, GRID_AUTOSAVE, GRID_COL_FIELDS, SHOW_ONLY_MASTER_PRODUCTS } from "@utils/constants"

const INITIAL_STATE = {
  columnFields: getGridColumnFields(),
  isGridAutoSaveEnabled: getGridAutosave() ?? true,
  bulkEditPayload: null,
  countryCodes: getGridCountryCodes(),
  isFetching: false,
  showOnlyMasterProducts: getGridShowOnlyMaster() ?? true,
}

const gridSlice = createSlice({
  name: "grid",
  initialState: INITIAL_STATE,
  reducers: {
    saveColumnFieldAtIndex: (state, { type, payload }) => {
      if (!state.columnFields.length) return
      
      const foundIndex = state.columnFields.findIndex(o => o.fieldCode === payload.colDef.fieldCode)
      if (foundIndex > -1) state.columnFields.splice(foundIndex, 1) // remove from previous index
      
      state.columnFields.splice(payload.toIndex, 0, payload.colDef) // insert from previous index

      // persist data in localStorage
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(GRID_COL_FIELDS, JSON.stringify(state.columnFields))
      }
    },
    showHideAllColumns: (state, { payload }) => {
      if (!state.columnFields.length) return
      const u = state.columnFields.map(col => ({
        ...col,
        hide: col.lockVisible ? col.hide : payload,
      }))
      // persist data in localStorage
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(GRID_COL_FIELDS, JSON.stringify(u))
      }
    },
    saveColumnField: (state, { type, payload }) => {
      if (!state.columnFields) state.columnFields = []
      
      const foundIndex = state.columnFields.findIndex(o => o.fieldCode === payload.fieldCode)
    
      if (foundIndex > -1) state.columnFields.splice(foundIndex, 1, payload)
      else state.columnFields.push(payload)

      // persist data in localStorage
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(GRID_COL_FIELDS, JSON.stringify(state.columnFields))
      }
    },
    saveAllColumnFields: (state, { type, payload }) => {
      // insert data into state
      state.columnFields = payload
      
      // persist data in localStorage
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(GRID_COL_FIELDS, JSON.stringify(state.columnFields))
      }
    },
    updateColumnFieldVisibility: (state, { type, payload }) => {
      const { columns } = payload
      if (!columns) return
      const u = [ ...state.columnFields ]
      columns.forEach((col) => {
        const foundIndex = u.findIndex(o => o.fieldCode === col.fieldCode)
        if (foundIndex > -1) u.splice(foundIndex, 1, col)
      })
      // set updated data
      state.columnFields = u
      // persist data in localStorage
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(GRID_COL_FIELDS, JSON.stringify(state.columnFields))
      }
    },
    updateGridAutoSave: (state, { payload }) => {
      // set updated data
      state.isGridAutoSaveEnabled = payload
      
      // persist data in localStorage
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(GRID_AUTOSAVE, JSON.stringify(state.isGridAutoSaveEnabled))
      }
    },
    updateBulkEditPayload: (state, { payload }) => {
      const { productId, data } = payload

      // init empty dataset
      if (!state.bulkEditPayload) state.bulkEditPayload = {}
      
      if (!state.bulkEditPayload[productId]) {
        // init structured empty dataset
        state.bulkEditPayload[productId] = {
          productId,
          data: [],
        }
      }
      
      if (state.bulkEditPayload[productId].data.length > 0) {
        const foundIndex = state.bulkEditPayload[productId].data.findIndex(
          (o) => o.fieldCode === data.fieldCode
        )

        if (foundIndex > -1) {
          // replace existing values
          state.bulkEditPayload[productId].data.splice(foundIndex, 1, {
            fieldCode: data.fieldCode,
            fieldValue: data.fieldValue,
          })
        } else {
          // insert new values
          state.bulkEditPayload[productId].data = [
            ...state.bulkEditPayload[productId].data, // previous data
            {
              fieldCode: data.fieldCode,
              fieldValue: data.fieldValue,
            },
          ]
        }
      } else {
        state.bulkEditPayload[productId].data = [
          {
            fieldCode: data.fieldCode,
            fieldValue: data.fieldValue,
          },
        ]
      }
    },
    resetBulkEditPayload: (state) => {
      state.bulkEditPayload = null
    },
    saveCountryCodes: (state, { payload }) => {
      state.countryCodes = payload;

      // persist data in localStorage
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(
          COUNTRY_CODE,
          JSON.stringify(state.countryCodes)
        );
      }
    },
    saveGenders: (state, { payload }) => {
      state.genders = payload;

      // persist data in localStorage
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(
          GENDER,
          JSON.stringify(state.genders)
        );
      }
    },
    setDataFetching: (state, { payload }) => {
      state.isFetching = payload
    },
    setShowOnlyMasterProducts: (state, { payload }) => {
      state.showOnlyMasterProducts = payload
      // persist data in localStorage
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(SHOW_ONLY_MASTER_PRODUCTS, state.showOnlyMasterProducts);
      }
    },
  }
})

const { actions, reducer } = gridSlice
export const {
  saveColumnField,
  showHideAllColumns,
  saveColumnFieldAtIndex,
  saveAllColumnFields,
  updateColumnFieldVisibility,
  updateGridAutoSave,
  updateBulkEditPayload,
  resetBulkEditPayload,
  saveCountryCodes,
  setDataFetching,
  saveGenders,
  setShowOnlyMasterProducts,
} = actions
export default reducer
