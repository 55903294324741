import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";

import {
    AUTHORIZATION_HEADER,
    DEFAULT_ERROR_MESSAGE,
    METHOD_DELETE,
    METHOD_POST,
    METHOD_PUT,
    METHOD_PATCH,
    RTK_API_BASE_URL,
    METHOD_GET,
    MASTERDATA_VALUES,
} from "utils/constants";
import { saveCountryCodes, saveGenders, setDataFetching } from '@redux/grid';

export const bulkEditorApi = createApi({

    /**
     * The `reducerPath` is a _unique_ key that your service will be mounted to in your store. If you call `createApi` more than once in your application, you will need to provide a unique value each time. Defaults to `'api'`.
     */
    reducerPath: "bulkEditorApi",

    /**
     * The base query used by each endpoint if no `queryFn` option is specified. RTK Query exports a utility called [fetchBaseQuery](./fetchBaseQuery) as a lightweight wrapper around `fetch` for common use-cases. See [Customizing Queries](../../rtk-query/usage/customizing-queries) if `fetchBaseQuery` does not handle your requirements.
     */
    baseQuery: fetchBaseQuery({
        baseUrl: RTK_API_BASE_URL,

        /*prepareHeaders: (headers, { getState }) => {
            const { token } = getState().auth;

            if (token) {
                headers.set(AUTHORIZATION_HEADER, token);
            }
            return headers;
        },*/
    }),

    /**
     * A function that is passed every dispatched action. If this returns something other than `undefined`,
     * that return value will be used to rehydrate fulfilled & errored queries.
     */
    extractRehydrationInfo(action) {
        if (action.type === HYDRATE) {
            return action.payload.api;
        }
    },

    /**
     * An array of string tag type names. Specifying tag types is optional, but you should define them so that they can be used for caching and invalidation. When defining a tag type, you will be able to [provide](../../rtk-query/usage/automated-refetching#providing-tags) them with `providesTags` and [invalidate](../../rtk-query/usage/automated-refetching#invalidating-tags) them with `invalidatesTags` when configuring [endpoints](#endpoints).
     */
    tagTypes: ["Bulk-Editor", "Get-Categories", "Get-SubCategories", "Get-Masterdata", "Search-Hsncode", "Search-By", "Search-Advanced", "Bulk-Edit", "All-Products", "Get-Meta-Info", "Search-All", "Search-By-Parent-Product"],

    /**
     * Endpoints are just a set of operations that you want to perform against your server. You define them as an object using the builder syntax. There are two basic endpoint types: [`query`](../../rtk-query/usage/queries) and [`mutation`](../../rtk-query/usage/mutations).
     */
    endpoints: (builder) => ({

        getCategories: builder.query({
            query: () => ({
                url: `bulk-editor/get-categories`,
            }),
            transformErrorResponse: (response) => response?.error,
            providesTags: [{ type: "Get-Categories" }],
        }),

        getSubCategories: builder.query({
            query: (name) => ({
                url: `bulk-editor/get-sub-categories`,
                method: METHOD_GET,
                params: { name: name },
            }),
            // transformErrorResponse: (response) => response?.error,
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                dispatch(setDataFetching(true));
                try {
                    const { data } = await queryFulfilled;
                    onSuccessFn(data);
                    dispatch(setDataFetching(false));
                } catch ({ error }) {
                    onErrorFn(error);
                    dispatch(setDataFetching(false));
                }
            },
            providesTags: [{ type: "Get-SubCategories" }],
        }),

        getMasterdata: builder.query({
            query: (id) => ({
                url: `bulk-editor/get-masterdata`,
                method: METHOD_GET,
                params: { id: id },
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data, meta } = await queryFulfilled;
                    onSuccessFn(data);
                    let urlParam = new URL(meta?.request?.url);
                    urlParam = urlParam.searchParams.get('id');
                    if (urlParam === MASTERDATA_VALUES.coo) {
                      dispatch(saveCountryCodes(data?.result || []));
                    }
                    if (urlParam === MASTERDATA_VALUES.gender) {
                      dispatch(saveGenders(data?.result || []));
                    }
                } catch ({ error }) {
                    onErrorFn(error);
                }
            },
            // transformErrorResponse: (response) => response?.error,
            providesTags: [{ type: "Get-Masterdata" }],
        }),

        searchHsncode: builder.query({
            query: ({ freeText }) => ({
                url: `bulk-editor/search-hsncode`,
                method: METHOD_GET,
                params: { freeText },
            }),
            // transformErrorResponse: (response) => response?.error,
            providesTags: [{ type: "Search-Hsncode" }],
        }),

        searchBy: builder.query({
            query: (params) => ({
                url: `bulk-editor/search-by`,
                method: METHOD_GET,
                params: params,
            }),
            transformErrorResponse: (response) => response?.error,
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                dispatch(setDataFetching(true));
                try {
                    const { data } = await queryFulfilled;
                    onSuccessFn(data);
                    dispatch(setDataFetching(false));
                } catch ({ error }) {
                    onErrorFn(error);
                    dispatch(setDataFetching(false));
                }
            },
            providesTags: [{ type: "Search-By" }],
        }),

        searchAll: builder.query({
            query: (params) => ({
                url: `bulk-editor/search-all`,
                method: METHOD_GET,
                params: params,
            }),
            transformErrorResponse: (response) => response?.error,
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                dispatch(setDataFetching(true));
                try {
                    const { data } = await queryFulfilled;
                    onSuccessFn(data);
                    dispatch(setDataFetching(false));
                } catch ({ error }) {
                    onErrorFn(error);
                    dispatch(setDataFetching(false));
                }
            },
            providesTags: [{ type: "Search-All" }],
        }),

        getMetaInfo: builder.query({
            query: () => ({
                url: `bulk-editor/get-meta-info`,
            }),
            transformErrorResponse: (response) => response?.error,
            providesTags: [{ type: "Get-Meta-Info" }],
        }),

        getAllProducts: builder.query({
            query: (id) => ({
                url: `product/${id}`,
                method: METHOD_GET,
            }),
            transformErrorResponse: (response) => response?.error,
            providesTags: [{ type: "All-Products" }],
        }),

        getProductData: builder.mutation({
            query: ({payload}) => ({
                url: `product/get-data`,
                method: METHOD_POST,
                body: payload
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                dispatch(setDataFetching(true));
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setDataFetching(false));
                    onSuccessFn(data);
                } catch ({ error }) {
                    dispatch(setDataFetching(false));
                    onErrorFn(error);
                }
            },
            invalidatesTags: [{ type: "All-Products" }],
        }),
        
        searchAdvanced: builder.mutation({
            query: ({ payload }) => ({
                url: `bulk-editor/search-advanced`,
                method: METHOD_POST,
                body: payload,
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                dispatch(setDataFetching(true));
                try {
                    const { data } = await queryFulfilled;
                    onSuccessFn(data);
                    dispatch(setDataFetching(false));
                } catch ({ error }) {
                    dispatch(setDataFetching(false));
                    onErrorFn(error);
                }
            },
            invalidatesTags: [{ type: "Search-Advanced" }],
        }),

        bulkEdit: builder.mutation({
            query: ({ payload }) => ({
                url: `bulk-editor`,
                method: METHOD_PUT,
                body: payload,
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    onSuccessFn(data);
                } catch ({ error }) {
                    onErrorFn(error);
                }
            },
            invalidatesTags: [{ type: "Bulk-Edit" }],
        }),
        
        productBulkEditAnyField: builder.mutation({
            query: ({ payload }) => ({
                url: `product/any-field`,
                method: METHOD_PUT,
                body: payload,
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    onSuccessFn(data);
                } catch ({ error }) {
                    onErrorFn(error);
                }
            },
            invalidatesTags: [{ type: "Bulk-Edit" }],
        }),

        productBulkEdit: builder.mutation({
            query: ({ payload }) => ({
                url: `product/edits`,
                method: METHOD_PUT,
                body: payload,
            }),
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                try {
                    const { data } = await queryFulfilled;
                    onSuccessFn(data);
                } catch ({ error }) {
                    onErrorFn(error);
                }
            },
            invalidatesTags: [{ type: "Bulk-Edit" }],
        }),

        searchByParentProduct: builder.query({
            query: (params) => ({
                url: `bulk-editor/search-by-parent-product`,
                method: METHOD_GET,
                params: params,
            }),
            transformErrorResponse: (response) => response?.error,
            async onQueryStarted(
                { onSuccessFn = () => { }, onErrorFn = (error) => { } },
                { dispatch, queryFulfilled }
            ) {
                dispatch(setDataFetching(true));
                try {
                    const { data } = await queryFulfilled;
                    onSuccessFn(data);
                    dispatch(setDataFetching(false));
                } catch ({ error }) {
                    onErrorFn(error);
                    dispatch(setDataFetching(false));
                }
            },
            providesTags: [{ type: "Search-By-Parent-Product" }],
        }),
    }),
});

export const {
    useGetCategoriesQuery, useGetSubCategoriesQuery, useGetMasterdataQuery, useSearchHsncodeQuery, useSearchByQuery, useSearchAllQuery, useGetMetaInfoQuery, useSearchAdvancedMutation, useBulkEditMutation, useGetAllProductsQuery, useGetProductDataMutation, useProductBulkEditMutation, useProductBulkEditAnyFieldMutation, useSearchByParentProductQuery
} = bulkEditorApi;