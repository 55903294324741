import { decrypt } from '@utils/cipher';
import { ORG_SETTINGS } from "@utils/constants";

export default () => {
  if (typeof localStorage !== "undefined") {
    const orgSettings = localStorage.getItem(ORG_SETTINGS);

    try {
      if (orgSettings) {
        const data = JSON.parse(decrypt(orgSettings));
        return data;
      }
    } catch (err) {
      return null;
    }
  }
  return undefined;
};
