import { decrypt } from "@utils/cipher";
import { API_TOKEN } from "@utils/constants";
import Cookies from "js-cookie";

export default () => {
  if (typeof Cookies !== "undefined") {
    const apiToken = Cookies.get(API_TOKEN);

    try {
      if (apiToken) {
        const token = JSON.parse(decrypt(apiToken));
        return token;
      }
    } catch (err) {
      return null;
    }
  }
  return undefined;
};
